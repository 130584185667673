import { Button, Checkbox, FormControlLabel, Input, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import MDBox from 'components/MDBox'
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react'
import { axiosClient } from 'Api/axoisClient';
import Swal from 'sweetalert2';
import { convertImageToBase64 } from 'Helpers/ImageHelper';

const PlanModal = ({open, setOpen, plan, action, setReload}) => {
  
    const [planInfo, setPlanInfo] = useState({
        id : plan?.id || '',
        type : plan?.type || '',
        image : plan?.image || '',
        max_announcements : plan?.max_announcements || '',
        max_pinned_announcements : plan?.max_pinned_announcements || '',
        max_favorites_announcements : plan?.max_favorites_announcements || '',
    })
    const [errors, setErrors] = useState([])

    const hundelChange = async (e) => {

        if(e.target.name == 'image' && e.target?.files){

          setPlanInfo({
                ...planInfo,
                image : await convertImageToBase64(e.target?.files[0])
          })

        }else {

          setPlanInfo((pre) => {
              return {
                  ...pre,
                  [e.target.name] : e.target?.value || null
              }
          })

        }


    }

    const updatePlan = async (e, id) => {

        e.preventDefault()

        try {

            const res = await axiosClient.put(`/plans/${id}`, planInfo)

            if(res.status == 200){

                setErrors([])
                Swal.fire({
                    icon: "success",
                    title: "plan modifiée avec succès",
                });
                setReload(pre => !pre)
                setOpen(false);

            }

        } catch (err) {

            if(err?.response?.stats == 422){

                setErrors(err?.response?.data?.messages)

            }else {
                setErrors(['parfois faux, veuillez réessayer']);
            }

        }

    }

    const createPlan = async (e) => {

        e.preventDefault()

        try {

            const res = await axiosClient.post(`/plans`, planInfo)

            if(res.status == 200){

                setErrors([])
                Swal.fire({
                    icon: "success",
                    title: "Plan crée avec succès",
                });
                setReload(pre => !pre)
                setOpen(false);

            }

        } catch (err) {

            if(err?.response?.status == 422){

                setErrors(Object.values(err?.response?.data))

            }else {
                setErrors(['parfois faux, veuillez réessayer']);
            }

        }

    }

    return (
        <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-[50%] left-[50%] w-[98%] sm:w-[70%] lg:w-[55%] bg-slate-50 rounded-lg shadow-lg -translate-y-[50%] -translate-x-[50%]">
        <MDBox
          className=" flex justify-between rounded-t-lg p-5"
          variant="gradient"
          bgColor="info"
        >
          <Typography
            sx={{ color: "white !important" }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            {
                action === 'create' ? 'Crée ' : 'Mise à jour '
            } plan
          </Typography>
          <CloseIcon
            onClick={() => setOpen(false)}
            className="text-white cursor-pointer"
          />
        </MDBox>
        <Box
          id="modal-modal-description"
          className="p-5 flex gap-y-8 flex-col"
        >

        {
            errors?.length > 0 && (
                <Box className='p-2 text-white flex flex-col rounded-lg bg-red-500'>
                    {
                        errors?.map((item, index) => (
                            <Typography fontSize={16} key={index} component="body2">{item[0]}</Typography>
                        ))
                    }
                </Box>
            )
        }

            <Typography sx={{ fontWeight: 500, fontSize: "14px" }} component="body2">* Laissez vide le champ où vous souhaitez que le numéro soit illimité</Typography>
          
          <Box className="flex items-center gap-3">
            <Typography
              className="w-1/4"
              sx={{ fontWeight: 500, fontSize: "16px" }}
              component="body2"
            >
              Badge
            </Typography>
            <Typography className="w-3/4" component="body2">
              {
                planInfo.image ? (
                  <img 
                    src={planInfo.image || null}
                    alt={planInfo.type || "Default plan"} // Fallback for alt attribute
                    className='w-[40px] h-[40px]' 
                  />
                ) : (
                  <p className='text-[16px]'>no image</p>
                )
              }
            </Typography>
          </Box>

          <Box className="flex items-center gap-3">
            <Typography
              className="w-1/4"
              sx={{ fontWeight: 500, fontSize: "16px" }}
              component="body2"
            >
              {
                action === 'create' ? 'Upload image' : 'Modifier badge'
              }
            </Typography>
            <Typography className="w-3/4" component="body2">
              <input 
                type='file'
                name='image'
                accept="image/*"
                onChange={(e) => hundelChange(e)}
                maxLength={1}
              />
            </Typography>
          </Box>

          <Box className="flex items-center gap-3">
            <Typography
              className="w-1/4"
              sx={{ fontWeight: 500, fontSize: "16px" }}
              component="body2"
            >
              Type
            </Typography>
            <Input
                value={planInfo?.type} 
                onChange={(e) => hundelChange(e)} 
                className='w-3/4' 
                name='type'
                type="text"
            >
            </Input>
          </Box>

          <Box className="flex flex-col md:flex-row items-center gap-3">
            <Typography
              className="w-full md:w-2/4"
              sx={{ fontWeight: 500, fontSize: "16px" }}
              component="body2"
            >
              Max announcements(*)
            </Typography>
            <Input 
                value={planInfo?.max_announcements} 
                onChange={(e) => hundelChange(e)} 
                className='w-full md:w-2/4' 
                name='max_announcements'
                type="number"
            >
            </Input>
          </Box>

          <Box className="flex flex-col md:flex-row items-center gap-3">
            <Typography
              className="w-full md:w-2/4"
              sx={{ fontWeight: 500, fontSize: "16px" }}
              component="body2"
            >
              Max announcements épinglés(*)
            </Typography>
            <Input 
                value={planInfo?.max_pinned_announcements} 
                onChange={(e) => hundelChange(e)} 
                className='w-full md:w-2/4' 
                name='max_pinned_announcements'
                type="number"
            >
            </Input>
          </Box>

          <Box className="flex flex-col md:flex-row items-center gap-3">
            <Typography
              className="w-full md:w-2/4"
              sx={{ fontWeight: 500, fontSize: "16px" }}
              component="body2"
            >
              Max announcements favorities(*)
            </Typography>
            <Input 
                value={planInfo?.max_favorites_announcements} 
                onChange={(e) => hundelChange(e)} 
                className='w-full md:w-2/4' 
                name='max_favorites_announcements'
                type="number"
            ></Input>
          </Box>

          <Box className="flex justify-center">

            {
                action === 'create' && (
                    <Button
                        onClick={(e) => createPlan(e)}
                        variant="contained"
                        bgColor="info"
                        sx={{color : '#ffffff'}}
                    >
                        Crée
                    </Button>
                )
            }

            {
                action === 'update' && (
                    <Button
                        onClick={(e) => updatePlan(e, planInfo?.id)}
                        variant="contained"
                        bgColor="info"
                        sx={{color : '#ffffff'}}
                    >
                        Mise à jour
                    </Button>
                )
            }

              
          </Box>

        </Box>
      </Box>
    </Modal>
    )
}

export default PlanModal