import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import announcementReducer from "./slices/announcementSlice";
import companySlice from "./slices/companySlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    announcements: announcementReducer,
    companies: companySlice,
  },
});

export default store;
