/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons

import React from "react";
import Icon from "@mui/material/Icon";
import ApartmentIcon from '@mui/icons-material/Apartment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Plans from "layouts/plans/Plans";
import LoginWithSession from "pages/LoginWithSession";
import ErrorPage from "components/error/ErrorPage";
import IsSuspend from "components/error/IsSuspend";
import Views from "layouts/views/Views";
import GroupIcon from '@mui/icons-material/Group';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import SalesStatistics from "layouts/Statistics/SalesStatistics";

// Material Dashboard 2 React layouts
const Register = React.lazy(() => import("pages/register/Register"));
const Companies = React.lazy(() => import("layouts/Admin/Companies/Companies"));
const AdminDashboard = React.lazy(() => import("layouts/Admin/Dashboard/AdminDashboard"));
const MyCommands = React.lazy(() => import("layouts/MyCommands/MyCommands"));
const Commands = React.lazy(() => import("layouts/Commands/Commands"));
const Dashboard = React.lazy( () => import("layouts/dashboard"));
const Notifications = React.lazy( () => import("layouts/notifications"));
const Profile = React.lazy( () => import("layouts/profile"));
const LogOut = React.lazy( () => import("layouts/authentication/logout"));
const LoginPage = React.lazy( () => import("pages/Login"));
const CommandsStatistics = React.lazy( () => import("layouts/Statistics/CommandsStatistics"));
const AnnouncementStatistics = React.lazy( () => import("layouts/Statistics/AnnouncementStatistics"));
const Announcement = React.lazy( () => import("layouts/Announcements/Announcement"));
const MesAnnounces = React.lazy( () => import("layouts/Announcements/MesAnnounces"));

const routes = [
  {
    key: "login",
    name: "Login",
    route: "/",
    icon: <Icon fontSize="small">Login</Icon>,
    component: <LoginPage />,
  },
  {
    name: "register",
    key: "register",
    route: "/register",
    component: <Register />
  },
  {
    name: "login-with-s",
    key: "login-with-s",
    route: "/login-with-session/:token",
    component: <LoginWithSession />
  },
  {
    type: "collapse",
    name: "Tableau de board",
    key: "entreprise",
    belongToAdmin : false,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/entreprise",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Mes Announces",
    key: "mes-announces",
    belongToAdmin : false,
    icon: <ShoppingBagIcon fontSize="small"/>,
    route: "/mes-announces",
    component: <MesAnnounces />,
  },
  {
    type: "collapse",
    name: "Mes Commandes",
    key: "mes-commands",
    belongToAdmin : false,
    icon: <ShoppingBagIcon fontSize="small"/>,
    route: "/mes-commands",
    component: <MyCommands />,
  },
  {
    type: "collapse",
    name: "Commandes",
    key: "commands",
    belongToAdmin : false,
    icon: <MonetizationOnIcon fontSize="small"/>,
    route: "/commands",
    component: <Commands />,
  },
  {
    type: "collapse",
    name: "Visiteurs",
    key: "visiteurs",
    belongToAdmin : false,
    icon: <GroupIcon fontSize="small"/>,
    route: "/visiteurs",
    component: <Views />,
  },
  {
    type: "collapse",
    name: "Tableau de board",
    key: "bw-admin-wdash",
    belongToAdmin : true,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/bw-admin-wdash",
    component: <AdminDashboard />,
  },
  {
    type: "collapse",
    name: "Entreprises",
    key: "entreprises",
    belongToAdmin : true,
    icon: <ApartmentIcon fontSize="small"/>,
    route: "/entreprises",
    component: <Companies />,
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    belongToAdmin : true,
    icon: <ViewStreamIcon fontSize="small"/>,
    route: "/plans",
    component: <Plans />,
  },
  {
    name: "commands statistics",
    key: "commands-statistics",
    route: "/commands-statistics",
    component: <CommandsStatistics />,
  },
  {
    name: "Announcement statistics",
    key: "announcement-statistics",
    route: "/announcement-statistics",
    component: <AnnouncementStatistics />,
  },
  {
    name: "Ventes statistics",
    key: "ventes-statistics",
    route: "/ventes-statistics",
    component: <SalesStatistics />,
  },
  {
    name: "Annonces",
    key: "announcement",
    route: "/announcement/:ref",
    component: <Announcement />,
  },
  {
    name: "Errors",
    key: "Errors",
    route: "/errors/:code",
    component: <ErrorPage />,
  },
  {
    name: "Forbiden",
    key: "Forbiden",
    route: "/forbiden",
    component: <IsSuspend />,
  },
  {
    // type: "collapse",
    name: "Profil",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
   {
    //  type: "collapse",
     name: "Notifications",
     key: "notifications",
     belongToAdmin : false,
     icon: <Icon fontSize="small">notifications</Icon>,
     route: "/notifications",
     component: <Notifications />,
   },
  {
    type: "collapse",
    name: "Deconnecter",
    key: "Deconnecter",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <LogOut />
  }
];

export default routes;
