import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const LoadingCom = () => {

    return (

        <Box className='fixed flex items-center justify-center w-full h-full top-0 right-0'>
            <CircularProgress size={45} color='warning' /> 
        </Box>

    )

}

export default LoadingCom