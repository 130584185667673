import MDButton from 'components/MDButton'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const IsSuspend = () => {

    const navigate = useNavigate();
    
    const redirect = (e) => {

        e.preventDefault()
        const previousPath = localStorage.getItem('previousPath') || '/';
        localStorage.removeItem('previousPath'); 
        navigate(previousPath);

    }

  return (
    <section className="page_404">
        <div className="container">
            <div className="row"> 
            <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
            <div className="four_zero_four_bg">
            <h1 className="text-center ">403</h1>
            
            
            </div>
            
            <div className="contant_box_404">
            <h3 className="h2">
                Votre entreprice est suspendue
            </h3>
            
            <p className="mb-4">Quelque chose ne va pas, veuillez réessayer</p>
            
            <MDButton
                onClick={(e) => redirect(e)}
                rel="noreferrer"
                variant="gradient"
                color="info"
                >
                Réessayer
            </MDButton>

        </div>
            </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default IsSuspend