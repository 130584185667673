import MDBox from 'components/MDBox'
import React, { useEffect, useState } from 'react'
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import ContactModal from 'components/Modals/commands/ContactModal';
import MailIcon from '@mui/icons-material/Mail';
// import window from 'Api/echo';

const Inbox = () => {

    const [open, setOpen] = useState(false)
    const [NewMessage, setNewMessage] = useState(false)

    const my_id = JSON.parse(localStorage.getItem('user'))?.id;
    
    // useEffect(() => {

    //     const chatChannel = window.Echo.private('new_message.' + my_id);

    //     chatChannel.listen('.NewMessage', (data) => {
            
    //         setNewMessage(!open ? true : false)

    //     });

    //     return () => {
    //         chatChannel.stopListening('.NewMessage');
    //         window.Echo.leaveChannel('private-new_message.' + my_id);
    //     };

    // }, [])

    useEffect(() => {

        if(open && NewMessage) {
            setNewMessage(false)
        }

    }, [open])

    useEffect(() => {

        if(open) {
            setNewMessage(false)
        }

    }, [NewMessage])

    if (localStorage.getItem('isAdmin') == 'true') return null; 


  return (
    <>
        {
            open && <ContactModal userId="null" setOpen={setOpen} open={open} />
        }
        <MDBox className='relative' onClick={() => setOpen(true)}>
            <SidenavCollapse
                name='Inbox'
                icon={<MailIcon/>}
                active={open}
            />
            {
                NewMessage && <div className="absolute top-[29%] rounded-full bg-red-500 left-[18%] w-[10px] h-[10px]"></div>
            }
        </MDBox>
    </>
  )
}

export default Inbox