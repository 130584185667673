import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  announcements: [],
};

const announcementSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    setAnnouncements(state, action) {

      if(action.payload){
        // localStorage.setItem("announcements", action.payload)
        state.announcements = action.payload;
      }

    },
  },
});
export const getSpesificAnn = (list, ref) => {
  const numericRef = Number(ref);
  const ann = list.find((ann) => ann.ref === numericRef);
  localStorage.setItem('ann', JSON.stringify(ann));
  return ann;
};

export const { setAnnouncements } = announcementSlice.actions;
export default announcementSlice.reducer;
