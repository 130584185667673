import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: JSON.parse(localStorage.getItem("isAuthenticated")) ?? false,
  token: localStorage.getItem("token") ?? null,
  user: JSON.parse(localStorage.getItem("user")) ?? null,
  isAdmin: JSON.parse(localStorage.getItem("isAdmin")),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      if (action.payload !== "") {
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("token", action.payload);

        state.isAuthenticated = true;
        state.token = action.payload;
      }
    },
    setUser(state, action) {
      if (action.payload.user && typeof action.payload.isAdmin == "boolean") {
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("isAdmin", action.payload.isAdmin);
        state.user = action.payload.user;
        state.isAdmin = action.payload.isAdmin;
      }
    },
    logout(state) {
      localStorage.clear();
      state.isAuthenticated = false;
      state.token = null;
    },
  },
});

export const { login, setUser, logout } = authSlice.actions;
export default authSlice.reducer;
