import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: JSON.parse(localStorage.getItem("company")) ?? [],

};

const announcementSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany(state, action) {
      
      if(action.payload){
        localStorage.setItem('company', JSON.stringify(action.payload))
        state.company = action.payload;
      }
    },
  },
});

export const { setCompany } = announcementSlice.actions;
export default announcementSlice.reducer;
