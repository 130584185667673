const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
    
            reader.onloadend = () => {
                resolve(reader.result); // استخدام resolve لإرجاع النتيجة عندما يكون جاهزًا
            };
    
            reader.onerror = reject; // إذا حدث خطأ، استخدم reject
    
            reader.readAsDataURL(file);
        } else {
            reject(new Error('No file provided')); // إذا لم يتم توفير ملف، استخدم reject
        }
    });
};

const convertArrayImagesToBase64 = (files) => {

    const Imagesbase64 = files.map((item) => {
        new Promise((resolve, reject) => {
            if (item) {
                const reader = new FileReader();
        
                reader.onloadend = () => {
                    resolve(reader.result); // استخدام resolve لإرجاع النتيجة عندما يكون جاهزًا
                };
        
                reader.onerror = reject; // إذا حدث خطأ، استخدم reject
        
                reader.readAsDataURL(item);
            } else {
                reject(new Error('No file provided')); // إذا لم يتم توفير ملف، استخدم reject
            }
        });
    }) 
    
    return Imagesbase64;

}

export {
    convertImageToBase64,
    convertArrayImagesToBase64
}