import axios from "axios";

// Create an axios instance
export const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_LINK}/api/`,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  headers: {
    Accept: "application/json",
  },
});

// Axios request interceptor to set Authorization header dynamically
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Optional: Global error logging in response interceptor
axiosClient.interceptors.response.use(null, (err) => {
  return Promise.reject(err);
});

// axiosClient.interceptors.response.use(
//   response => response,
//   error => {
    
//       if (error?.response?.status === 403) {
//         localStorage.setItem('previousPath', window.location.pathname);
//         window.location.href = '/errors/403'
//       } else if (error?.response?.status === 401) {

//         if(window.location.pathname != '/'){

//           localStorage.clear()
//           window.location.href = '/'

//         }

//       } else if(error?.response?.status !== 422 || !error?.response) {
//         localStorage.setItem('previousPath', window.location.pathname);
//         window.location.href = '/errors/500'
//       }
//       return Promise.reject(error);
//   }
// );
